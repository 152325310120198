<template>
    <div class="projectIntroduction">
        <Top-Nav></Top-Nav>
        项目介绍
    </div>
</template>
<script>
import TopNav from '../../components/common/TopNav.vue';
export default {
  name: 'ProjectIntroduction',
  components:{TopNav},
  data() {
    return {
      
    }
  },
  methods:{
    
  }
}
</script>